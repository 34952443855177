"use strict";
! function(e) {
    e.matches = e.matches || e.mozMatchesSelector || e.msMatchesSelector || e.oMatchesSelector || e.webkitMatchesSelector, e.closest = e.closest || function(e) {
        return this ? this.matches(e) ? this : this.parentElement ? this.parentElement.closest(e) : null : null
    }
}(Element.prototype);
var demoMode = function() {
    var e, t, a, o, l, r, n, s, c = document.querySelector("#popoverDemo"),
        i = document.querySelector("#demoForm"),
        d = document.querySelector("#topnav"),
        u = document.querySelector("#topbar"),
        f = document.querySelector("#sidebar"),
        h = document.querySelector("#sidebarSmall"),
        p = document.querySelector("#sidebarUser"),
        v = document.querySelector("#sidebarSmallUser"),
        g = document.querySelector("#sidebarSizeContainer"),
        m = document.querySelectorAll('input[name="navPosition"]'),
        b = document.querySelectorAll('[class^="container"]'),
        S = document.querySelector("#stylesheetLight"),
        y = document.querySelector("#stylesheetDark"),
        C = {
            showPopover: !localStorage.getItem("dashkitShowPopover") || localStorage.getItem("dashkitShowPopover"),
            colorScheme: localStorage.getItem("dashkitColorScheme") ? localStorage.getItem("dashkitColorScheme") : "light",
            navPosition: localStorage.getItem("dashkitNavPosition") ? localStorage.getItem("dashkitNavPosition") : "sidenav",
            navColor: localStorage.getItem("dashkitNavColor") ? localStorage.getItem("dashkitNavColor") : "default",
            sidebarSize: localStorage.getItem("dashkitSidebarSize") ? localStorage.getItem("dashkitSidebarSize") : "base"
        };

    function k(e) {
        "topnav" == e ? $(g).collapse("hide") : $(g).collapse("show")
    }

    function L(e) {
        e && e.setAttribute("style", "display: none !important")
    }
    return c && (JSON.parse(C.showPopover) && "base" === C.sidebarSize && $(c).popover({
            boundary: "viewport",
            offset: "50px",
            placement: "top",
            template: '<div class="popover popover-lg popover-dark d-none d-md-block" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
        }).popover("show"), c.addEventListener("click", function() {
            $(c).popover("hide"), localStorage.setItem("dashkitShowPopover", !1)
        })),
        function() {
            for (var e = window.location.search.substring(1).split("&"), t = 0; t < e.length; t++) {
                var a = e[t].split("="),
                    o = a[0],
                    l = a[1];
                "colorScheme" != o && "navPosition" != o && "navColor" != o && "sidebarSize" != o || (localStorage.setItem("dashkit" + o.charAt(0).toUpperCase() + o.slice(1), l), C[o] = l)
            }
        }(), "light" == (e = C.colorScheme) ? (true, true, e = "light") : "dark" == e && (true, true, e = "dark"),
        function(e) {
            if (d && u && f && h && p && v)
                if ("topnav" == e) {
                    L(u), L(f), L(h);
                    for (var t = 0; t < b.length; t++) b[t].classList.remove("container-fluid"), b[t].classList.add("container")
                } else if ("combo" == e) {
                L(d), L(p), L(v);
                for (t = 0; t < b.length; t++) b[t].classList.remove("container"), b[t].classList.add("container-fluid")
            } else if ("sidenav" == e) {
                L(d), L(u);
                for (t = 0; t < b.length; t++) b[t].classList.remove("container"), b[t].classList.add("container-fluid")
            }
        }(C.navPosition), t = C.navColor, f && h && d && ("default" == t ? (f.classList.add("navbar-light"), h.classList.add("navbar-light"), d.classList.add("navbar-light")) : "inverted" == t ? (f.classList.add("navbar-dark"), h.classList.add("navbar-dark"), d.classList.add("navbar-dark")) : "vibrant" == t && (f.classList.add("navbar-dark", "navbar-vibrant"), h.classList.add("navbar-dark", "navbar-vibrant"), d.classList.add("navbar-dark", "navbar-vibrant"))), "base" == (a = C.sidebarSize) ? L(h) : "small" == a && L(f), o = i, l = C.colorScheme, r = C.navPosition, n = C.navColor, s = C.sidebarSize, $(o).find('[name="colorScheme"][value="' + l + '"]').closest(".btn").button("toggle"), $(o).find('[name="navPosition"][value="' + r + '"]').closest(".btn").button("toggle"), $(o).find('[name="navColor"][value="' + n + '"]').closest(".btn").button("toggle"), $(o).find('[name="sidebarSize"][value="' + s + '"]').closest(".btn").button("toggle"), k(C.navPosition), true, i && (i.addEventListener("submit", function(e) {
            var t, a, o, l, r;
            e.preventDefault(), a = (t = i).querySelector('[name="colorScheme"]:checked').value, o = t.querySelector('[name="navPosition"]:checked').value, l = t.querySelector('[name="navColor"]:checked').value, r = t.querySelector('[name="sidebarSize"]:checked').value, localStorage.setItem("dashkitColorScheme", a), localStorage.setItem("dashkitNavPosition", o), localStorage.setItem("dashkitNavColor", l), localStorage.setItem("dashkitSidebarSize", r), window.location = window.location.pathname
        }), [].forEach.call(m, function(e) {
            e.parentElement.addEventListener("click", function() {
                k(e.value)
            })
        })), !0
}();
! function() {
    var e = {
            300: "#E3EBF6",
            600: "#95AAC9",
            700: "#6E84A3",
            800: "#152E4D",
            900: "#283E59"
        },
        t = {
            100: "#D2DDEC",
            300: "#A6C5F7",
            700: "#2C7BE5"
        },
        a = "#FFFFFF",
        o = "transparent",
        l = "Cerebri Sans",
        r = document.querySelectorAll('[data-toggle="chart"]'),
        n = document.querySelectorAll('[data-toggle="legend"]');

    function f(t) {
        var a = void 0;
        return Chart.helpers.each(Chart.instances, function(e) {
            t == e.chart.canvas && (a = e)
        }), a
    }
    "undefined" != typeof Chart && (Chart.defaults.global.responsive = !0, Chart.defaults.global.maintainAspectRatio = !1, Chart.defaults.global.defaultColor = e[600], Chart.defaults.global.defaultFontColor = e[600], Chart.defaults.global.defaultFontFamily = l, Chart.defaults.global.defaultFontSize = 13, Chart.defaults.global.layout.padding = 0, Chart.defaults.global.legend.display = !1, Chart.defaults.global.legend.position = "bottom", Chart.defaults.global.legend.labels.usePointStyle = !0, Chart.defaults.global.legend.labels.padding = 16, Chart.defaults.global.elements.point.radius = 0, Chart.defaults.global.elements.point.backgroundColor = t[700], Chart.defaults.global.elements.line.tension = .4, Chart.defaults.global.elements.line.borderWidth = 3, Chart.defaults.global.elements.line.borderColor = t[700], Chart.defaults.global.elements.line.backgroundColor = o, Chart.defaults.global.elements.line.borderCapStyle = "rounded", Chart.defaults.global.elements.rectangle.backgroundColor = t[700], Chart.defaults.global.elements.rectangle.maxBarThickness = 10, Chart.defaults.global.elements.arc.backgroundColor = t[700], Chart.defaults.global.elements.arc.borderColor = a, Chart.defaults.global.elements.arc.borderWidth = 4, Chart.defaults.global.elements.arc.hoverBorderColor = a, Chart.defaults.global.tooltips.enabled = !1, Chart.defaults.global.tooltips.mode = "index", Chart.defaults.global.tooltips.intersect = !1, Chart.defaults.global.tooltips.custom = function(r) {
        var e = document.getElementById("chart-tooltip"),
            n = this._chart.config.type;
        if (e || ((e = document.createElement("div")).setAttribute("id", "chart-tooltip"), e.setAttribute("role", "tooltip"), e.classList.add("popover"), e.classList.add("bs-popover-top"), document.body.appendChild(e)), 0 !== r.opacity) {
            if (r.body) {
                var t = r.title || [],
                    a = r.body.map(function(e) {
                        return e.lines
                    }),
                    s = '<div class="arrow"></div>';
                t.forEach(function(e) {
                    s += '<h3 class="popover-header text-center">' + e + "</h3>"
                }), a.forEach(function(e, t) {
                    var a = r.labelColors[t],
                        o = '<span class="popover-body-indicator" style="background-color: ' + ("line" === n && "rgba(0,0,0,0.1)" !== a.borderColor ? a.borderColor : a.backgroundColor) + '"></span>',
                        l = 1 < e.length ? "justify-content-left" : "justify-content-center";
                    s += '<div class="popover-body d-flex align-items-center ' + l + '">' + o + e + "</div>"
                }), e.innerHTML = s
            }
            var o = this._chart.canvas.getBoundingClientRect(),
                l = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0,
                c = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0,
                i = o.top + l,
                d = o.left + c,
                u = e.offsetWidth,
                f = e.offsetHeight,
                h = i + r.caretY - f - 16,
                p = d + r.caretX - u / 2;
            e.style.top = h + "px", e.style.left = p + "px", e.style.visibility = "visible"
        } else e.style.visibility = "hidden"
    }, Chart.defaults.global.tooltips.callbacks.label = function(e, t) {
        var a = "",
            o = e.yLabel,
            l = t.datasets[e.datasetIndex],
            r = l.label,
            n = l.yAxisID ? l.yAxisID : 0,
            s = this._chart.options.scales.yAxes,
            c = s[0];
        if (n) c = s.filter(function(e) {
            return e.id == n
        })[0];
        var i = c.ticks.callback;
        return 1 < t.datasets.filter(function(e) {
            return !e.hidden
        }).length && (a = '<span class="popover-body-label mr-auto">' + r + "</span>"), a += '<span class="popover-body-value">' + i(o) + "</span>"
    }, Chart.defaults.doughnut.cutoutPercentage = 83, Chart.defaults.doughnut.tooltips.callbacks.title = function(e, t) {
        return t.labels[e[0].index]
    }, Chart.defaults.doughnut.tooltips.callbacks.label = function(e, t) {
        var a = t.datasets[0].data[e.index],
            o = this._chart.options.tooltips.callbacks,
            l = o.afterLabel() ? o.afterLabel() : "";
        return '<span class="popover-body-value">' + (o.beforeLabel() ? o.beforeLabel() : "") + a + l + "</span>"
    }, Chart.defaults.doughnut.legendCallback = function(e) {
        var o = e.data,
            l = "";
        return o.labels.forEach(function(e, t) {
            var a = o.datasets[0].backgroundColor[t];
            l += '<span class="chart-legend-item">', l += '<i class="chart-legend-indicator" style="background-color: ' + a + '"></i>', l += e, l += "</span>"
        }), l
    }, Chart.scaleService.updateScaleDefaults("linear", {
        gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: e[300],
            drawBorder: !1,
            drawTicks: !1,
            zeroLineColor: e[300],
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2]
        },
        ticks: {
            beginAtZero: !0,
            padding: 10,
            stepSize: 10
        }
    }), Chart.scaleService.updateScaleDefaults("category", {
        gridLines: {
            drawBorder: !1,
            drawOnChartArea: !1,
            drawTicks: !1
        },
        ticks: {
            padding: 20
        }
    }), r && [].forEach.call(r, function(e) {
        var t = e.dataset.trigger;
        e.addEventListener(t, function() {
            ! function(e) {
                var t = e.dataset.target,
                    a = e.dataset.action,
                    o = parseInt(e.dataset.dataset),
                    l = f(document.querySelector(t));
                if ("toggle" === a) {
                    var r = l.data.datasets,
                        n = r.filter(function(e) {
                            return !e.hidden
                        })[0],
                        s = r.filter(function(e) {
                            return 1e3 === e.order
                        })[0];
                    if (!s) {
                        for (var c in s = {}, n) "_meta" !== c && (s[c] = n[c]);
                        s.order = 1e3, s.hidden = !0, r.push(s)
                    }
                    var i = r[o] === n ? s : r[o];
                    for (var c in n) "_meta" !== c && (n[c] = i[c]);
                    l.update()
                }
                if ("add" === a) {
                    var d = l.data.datasets[o],
                        u = d.hidden;
                    d.hidden = !u
                }
                l.update()
            }(e)
        })
    }), n && document.addEventListener("DOMContentLoaded", function() {
        [].forEach.call(n, function(e) {
            var t, a, o;
            a = f(t = e).generateLegend(), o = t.dataset.target, document.querySelector(o).innerHTML = a
        })
    }))
}(),
function() {
    var e = {
            300: "#E3EBF6",
            600: "#95AAC9",
            700: "#6E84A3",
            800: "#152E4D",
            900: "#283E59"
        },
        t = localStorage.getItem("dashkitColorScheme") ? localStorage.getItem("dashkitColorScheme") : "light";

    function a() {
        Chart.defaults.global.defaultColor = e[700], Chart.defaults.global.defaultFontColor = e[700], Chart.defaults.global.elements.arc.borderColor = e[800], Chart.defaults.global.elements.arc.hoverBorderColor = e[800], Chart.scaleService.updateScaleDefaults("linear", {
            gridLines: {
                borderDash: [2],
                borderDashOffset: [2],
                color: e[900],
                drawBorder: !1,
                drawTicks: !1,
                zeroLineColor: e[900],
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2]
            },
            ticks: {
                beginAtZero: !0,
                padding: 10,
                callback: function(e) {
                    if (!(e % 10)) return e
                }
            }
        })
    }
    "undefined" != typeof Chart && (void 0 === demoMode || demoMode && "dark" == t) && a()
}(),
function() {
    var e = document.querySelectorAll('[data-toggle="autosize"]');
    "undefined" != typeof autosize && e && [].forEach.call(e, function(e) {
        autosize(e)
    })
}(),
function() {
    var e = document.querySelectorAll(".dropup, .dropright, .dropdown, .dropleft"),
        a = ["click"];
    [].forEach.call(e, function(t) {
        var e = t.querySelector('[data-toggle="dropdown"]');
        e && e.addEventListener(a[0], function(e) {
            ! function(e, t) {
                var a = t.closest(".dropdown-menu");
                if (a) {
                    e.preventDefault(), e.stopPropagation();
                    var o = t.querySelector(".dropdown-menu"),
                        l = a.querySelectorAll(".dropdown-menu");
                    [].forEach.call(l, function(e) {
                        e !== o && e.classList.remove("show")
                    }), o.classList.toggle("show")
                }
            }(e, t)
        })
    }), $(e).on("hide.bs.dropdown", function() {
        var e;
        (e = this.querySelector(".dropdown-menu").querySelectorAll(".dropdown-menu")) && [].forEach.call(e, function(e) {
            e.classList.remove("show")
        })
    })
}(),
function() {
    var e = document.querySelectorAll('[data-toggle="dropzone"]');
    "undefined" != typeof Dropzone && e && (Dropzone.autoDiscover = !1, Dropzone.thumbnailWidth = null, Dropzone.thumbnailHeight = null, [].forEach.call(e, function(e) {
        ! function(e) {
            var t = void 0,
                a = e.dataset.options;
            a = a ? JSON.parse(a) : {};
            var o = {
                    previewsContainer: e.querySelector(".dz-preview"),
                    previewTemplate: e.querySelector(".dz-preview").innerHTML,
                    init: function() {
                        this.on("addedfile", function(e) {
                            1 == a.maxFiles && t && this.removeFile(t), t = e
                        })
                    }
                },
                l = Object.assign(o, a);
            e.querySelector(".dz-preview").innerHTML = "", new Dropzone(e, l)
        }(e)
    }))
}(),
function() {
    var e = document.querySelectorAll('[data-toggle="flatpickr"]');
    "undefined" != typeof flatpickr && e && [].forEach.call(e, function(e) {
        var t, a;
        a = (a = (t = e).dataset.options) ? JSON.parse(a) : {}, flatpickr(t, a)
    })
}(),
function() {
    var e = document.querySelectorAll(".highlight");
    "undefined" != typeof hljs && e && [].forEach.call(e, function(e) {
        var t;
        t = e, hljs.highlightBlock(t)
    })
}(),
function() {
    var e = document.querySelectorAll(".kanban-category"),
        t = document.querySelectorAll(".kanban-add-link"),
        a = document.querySelectorAll(".kanban-add-form");

    function o(e) {
        var t = e.closest(".kanban-add"),
            a = t.querySelector(".card"),
            o = t.querySelector(".kanban-add-link"),
            l = t.querySelector(".kanban-add-form");
        o.classList.toggle("d-none"), l.classList.toggle("d-none"), a && a.classList.contains("card-sm") && (a.classList.contains("card-flush") ? a.classList.remove("card-flush") : a.classList.add("card-flush"))
    }
    "undefined" != typeof Draggable && e && new Draggable.Sortable(e, {
        draggable: ".kanban-item",
        mirror: {
            constrainDimensions: !0
        }
    }), t && [].forEach.call(t, function(e) {
        e.addEventListener("click", function() {
            o(e)
        })
    }), a && [].forEach.call(a, function(e) {
        e.addEventListener("reset", function() {
            o(e)
        }), e.addEventListener("submit", function(e) {
            e.preventDefault()
        })
    })
}(),
function() {
    var e = document.querySelectorAll("[data-list]"),
        t = document.querySelectorAll("[data-sort]");

    function h(e, t, a) {
        var o = [].slice.call(e).filter(function(e) {
            return e.checked
        });
        t && (o.length ? t.classList.add("show") : t.classList.remove("show"), a.innerHTML = o.length)
    }
    "undefined" != typeof List && e && [].forEach.call(e, function(e) {
        var t, a, o, l, r, n, s, c, i, d, u, f;
        a = (t = e).querySelector(".list-alert"), o = t.querySelector(".list-alert-count"), l = t.querySelector(".list-alert .close"), r = t.querySelectorAll(".list-checkbox"), n = t.querySelector(".list-checkbox-all"), s = t.querySelectorAll(".list-pagination"), c = t.querySelector(".list-pagination-prev"), i = t.querySelector(".list-pagination-next"), d = t.dataset.list && JSON.parse(t.dataset.list), u = Object.assign({
            listClass: "list",
            searchClass: "list-search",
            sortClass: "list-sort"
        }, d), f = new List(t, u), s && [].forEach.call(s, function(e) {
            e.addEventListener("click", function(e) {
                e.preventDefault()
            })
        }), i && i.addEventListener("click", function(e) {
            e.preventDefault();
            var t = f.i + f.page;
            t <= f.size() && f.show(t, f.page)
        }), c && c.addEventListener("click", function(e) {
            e.preventDefault();
            var t = f.i - f.page;
            0 < t && f.show(t, f.page)
        }), r && [].forEach.call(r, function(e) {
            e.addEventListener("change", function() {
                h(r, a, o), n && (n.checked = !1)
            })
        }), n && n.addEventListener("change", function() {
            [].forEach.call(r, function(e) {
                e.checked = n.checked
            }), h(r, a, o)
        }), l && l.addEventListener("click", function(e) {
            e.preventDefault(), n && (n.checked = !1), [].forEach.call(r, function(e) {
                e.checked = !1
            }), h(r, a, o)
        })
    }), "undefined" != typeof List && t && [].forEach.call(t, function(e) {
        e.addEventListener("click", function(e) {
            e.preventDefault()
        })
    })
}(),
function() {
    var e = document.querySelectorAll('[data-toggle="map"]');
    "undefined" != typeof mapboxgl && e && [].forEach.call(e, function(e) {
        ! function(e) {
            var t = e.dataset.options;
            t = t ? JSON.parse(t) : {};
            var a = {
                    container: e,
                    style: "mapbox://styles/mapbox/light-v9",
                    scrollZoom: !1,
                    interactive: !1
                },
                o = Object.assign(a, t);
            mapboxgl.accessToken = "pk.eyJ1IjoiZ29vZHRoZW1lcyIsImEiOiJjanU5eHR4N2cybDU5NGVwOHZwNGprb3E0In0.msdw9q16dh8v4azJXUdiXg", new mapboxgl.Map(o)
        }(e)
    })
}(),
function() {
    var e = document.querySelectorAll('.navbar-nav [data-toggle="collapse"]');
    [].forEach.call(e, function(a) {
        a.addEventListener("click", function() {
            var t, e;
            e = (t = a).closest(".collapse").querySelectorAll(".collapse"), [].forEach.call(e, function(e) {
                e !== t && $(e).collapse("hide")
            })
        })
    })
}(),
function() {
    var e = document.querySelectorAll('[data-toggle="popover"]');
    e && $(e).popover()
}(),
function() {
    var e = document.querySelectorAll('[data-toggle="quill"]');
    "undefined" != typeof Quill && e && [].forEach.call(e, function(e) {
        ! function(e) {
            var t = e.dataset.options;
            t = t ? JSON.parse(t) : {};
            var a = Object.assign({
                modules: {
                    toolbar: [
                        ["bold", "italic"],
                        ["link", "blockquote", "code", "image"],
                        [{
                            list: "ordered"
                        }, {
                            list: "bullet"
                        }]
                    ]
                },
                theme: "snow"
            }, t);
            new Quill(e, a)
        }(e)
    })
}(),
function() {
    var e = document.querySelectorAll('[data-toggle="select"]');

    function r(e) {
        if (!e.id || !e.element || !e.element.dataset.avatarSrc) return e.text;
        var t = e.element.dataset.avatarSrc,
            a = document.createElement("div");
        return a.innerHTML = '<span class="avatar avatar-xs mr-3"><img class="avatar-img rounded-circle" src="' + t + '" alt="' + e.text + '"></span><span>' + e.text + "</span>", a
    }
    jQuery().select2 && e && [].forEach.call(e, function(e) {
        var t, a, o, l;
        a = (t = e).dataset.options ? JSON.parse(t.dataset.options) : {}, o = {
            containerCssClass: t.getAttribute("class"),
            dropdownAutoWidth: !0,
            dropdownCssClass: t.classList.contains("custom-select-sm") || t.classList.contains("form-control-sm") ? "dropdown-menu dropdown-menu-sm show" : "dropdown-menu show",
            dropdownParent: t.closest(".modal-body") || document.body,
            templateResult: r
        }, l = Object.assign(o, a), $(t).select2(l)
    })
}(),
function() {
    var e = document.querySelectorAll('[data-toggle="tooltip"]');
    e && $(e).tooltip()
}(),
function() {
    var e = document.querySelectorAll(".checklist");
    "undefined" != typeof Draggable && e && new Draggable.Sortable(e, {
        draggable: ".checklist-control",
        handle: ".custom-control-caption",
        mirror: {
            constrainDimensions: !0
        }
    })
}(),
function() {
    var e = document.querySelectorAll('[data-toggle="wizard"]');
    [].forEach.call(e, function(t) {
        t.addEventListener("click", function(e) {
            e.preventDefault(), $(t).tab("show").removeClass("active")
        })
    })
}();